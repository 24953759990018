/* google fonts */
/* @import url("https://fonts.googleapis.com/css2?family=Questrial&display=swap"); */
@import url('https://fonts.googleapis.com/css2?family=Lato&family=Urbanist:wght@200;300;400;500;600;700;800&display=swap');

:root {
  --primary-color: #fff;
  --heading-color: #3cc4bd;
  --bg-gradient: linear-gradient(300.9deg, rgb(49, 199, 171) 27.8%, rgb(32, 170, 183) 67%);
  --font-name: "Urbanist", sans-serif;

  /* 
    #9785f2
    #b5a9f3
    #d3cef2
    #d7337a
  */
}


/* ======= reset default browser styles ======== */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: var(--font-name);
}

section {
  padding: 50px 0px;
}

h1,h2,h3,h4,h5 {
  font-weight: 700;
  color: var(--primary-color);
  text-transform: none;
  margin: 0;
  font-family: var(--font-name);
  background: transparent !important;
}

a {
  text-decoration: none;
  color: unset;
}

a:hover {
  color: unset;
}

ul {
  list-style: none;
}

p {
  font-family: var(--font-name);
  color: #fff;
  text-align: justify;
  line-height: 1;
  margin-bottom: 10px;
  letter-spacing: 0.5px;
  background: transparent !important;
}

/* ======= reusable class ======== */
.container {
  width: 1440px;
  margin-left: auto;
  margin-right: auto;
}

.section__title {
  color: var(--primary-color);
  font-weight: 700;
}

.logo__img img {
  height: 40px;
  width: 100px;
  margin-right: 10px;
}

.nav__item {
  color: var(--primary-color)
}

.mobile_menu {
  color: #fff;
}

.register__btn {
  color: black;
}

.btn {
  background: var(--bg-gradient);
  color: black;
}

::selection {
  background-color: var(--heading-color);
  color: #000;
}

.background-video {
  position: absolute;
  right: 0;
  left: 0;
  opacity: 0.5;
}

#video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}